.App {
  min-height: 100vh;
  display: flex;
  background-color: white;
  align-items: center;
  flex-direction: column;
}

.content {
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: "scroll";
}

.Header {
  min-width: 100vw;
  display: flex;
  background-color: white;
  flex-direction: row;
  position: fixed;
  top: 0px;
  border-bottom: 0.5px solid #bfbfbf;
}

.text {
  color: #4da6ff;
  font-weight: 600;
  font-size: calc(10px + 2vmin);
}

.appLogo {
  width: 100px;
  height: 100px;
  margin-top: 20vh;
}

.appDes {
  display: flex;
  flex-direction: row;
}

.appStoreCompCon {
  align-items: center;
  flex-direction: column;
  display: flex;
  margin-top: 40px;
  width: 240px;

  @media (min-width: 768px) {
    width: 320px;
  }
  @media (min-width: 1024px) {
    width: 500px;
  }
}

.appStoreCon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}
